import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SettingsService } from './settings';

@Injectable()
export class RestApiProvider {
  apiUrl = '';
  casUrl = '';
  fastUrl = '';
  token = '';
  org = '';

  constructor(public _ajustes: SettingsService, private http: HttpClient) {
    this.apiUrl = 'https://api.miurabox.info/';
    this.casUrl = 'https://users-api.miurabox.com/';
    this.fastUrl = 'https://heysaam-api-test.miurabox.com/';
    this.org = 'gpi';
    // this.apiUrl = 'https://test_api.miurabox.com/';
    // this.casUrl = 'https://test_cas.miurabox.com/';
    // this.apiUrl = 'http://127.0.0.1:8000/';
    // this.casUrl = 'http://127.0.0.1:9001/';
  }

  plans(){
    return new Promise((resolve,reject) => {
      let token =  this._ajustes['token'];
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Token '+token}
      this.http.get(this.casUrl + 'pagos/planes', {headers: headers})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  };

  login(username, password){
    return new Promise((resolve,reject) => {
      let headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json'
      }
      let data = 'username=' + encodeURIComponent(username) + '&password=' + encodeURIComponent(password) + '&org=' + encodeURIComponent(this.org)
      this.http.post(this.casUrl + 'api-token-auth-saam/', data = data, {headers : headers})
      .subscribe(data => {
        this.token = data['token_jwt'];
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  };

  signup(user){
    return new Promise((resolve,reject) => {
      let headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/x-www-form-urlencoded'
      }
      let data = 'first_name=' + encodeURIComponent(user.first_name) + '&password=' + encodeURIComponent(user.password)+ '&last_name=' + encodeURIComponent(user.last_name)+ '&email=' + encodeURIComponent(user.email)
      this.http.post(this.casUrl + 'cas-create-user', data = data, {headers: headers})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  };

  forgot_password(email){
    return new Promise((resolve,reject) => {
      let headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/x-www-form-urlencoded'
      }
      let data = 'email=' + encodeURIComponent(email)
      this.http.post(this.casUrl+'forgot-password', data = data, {headers: headers})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  };

  get_cards(param){
    return new Promise((resolve,reject) => {
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+this.token}
      this.http.get(this.fastUrl+'entries/'+param, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }
  
  get_subcards(id){
    return new Promise((resolve,reject) => {
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+this.token}
      this.http.get(this.fastUrl+'subtitles/'+id+'?skip=0&limit=100', { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }
  
  get_comments(id){
    return new Promise((resolve,reject) => {
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+this.token}
      this.http.get(this.fastUrl+'comments/'+id+'?skip=0&limit=100', { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }
  
  save_cards(item){
    return new Promise((resolve,reject) => {
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+this.token}
      this.http.post(this.fastUrl+'entries/', item, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }
  
  save_subcards(id, item){
    return new Promise((resolve,reject) => {
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+this.token}
      this.http.post(this.fastUrl+'entries/'+id+'/subtitles/', item, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  save_comments(id, item){
    return new Promise((resolve,reject) => {
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+this.token}
      this.http.post(this.fastUrl+'entries/'+id+'/comment/', item, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }
  
  save_image(id, file){
    return new Promise((resolve,reject) => {
      let headers = new HttpHeaders().set('Authorization',  'Bearer '+this.token);
      headers.append('Content-Type', 'multipart/form-data');
      
      this.http.post(this.fastUrl+'entries/'+id+'/files/', file, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  delete_cards(id){
    return new Promise((resolve,reject) => {
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+this.token}
      this.http.delete(this.fastUrl+'entries/'+id, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  delete_subcards(id){
    return new Promise((resolve,reject) => {
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+this.token}
      this.http.delete(this.fastUrl+'subtitles/'+id, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  delete_comments(id){
    return new Promise((resolve,reject) => {
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+this.token}
      this.http.delete(this.fastUrl+'comments/'+id, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }
  
  edit_cards(id, data){
    return new Promise((resolve,reject) => {
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+this.token}
      this.http.patch(this.fastUrl+'entries/'+id, data, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }
  
  edit_subcards(id, data){
    return new Promise((resolve,reject) => {
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+this.token}
      this.http.patch(this.fastUrl+'subtitle/'+id, data, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  send_contact(contact){
    return new Promise((resolve,reject) => {
      this.http.post(this.apiUrl+'enviar-correo-contacto', contact)
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  check_captcha(captcha:string){
    return new Promise((resolve,reject) => {
      this.http.post(this.apiUrl+'verificar-captcha', {captcha})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  solicitud_de_prueba(contact){
    return new Promise((resolve,reject) => {
      this.http.post(this.casUrl + 'test-request', contact)
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  existe_email(email:string){
    return new Promise((resolve,reject) => {
      this.http.post(this.casUrl + 'email-request-exists', {'email':email})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }
  
}