import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SEOService } from '../seo.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-benefit',
  templateUrl: './benefit.component.html',
  styleUrls: ['./benefit.component.css']
})
export class BenefitComponent implements OnInit {

  schema = { 
    "@context" : "http://schema.org",
    "@type" : "Article",
    "name" : "¿En qué somos mejores que la competencia",
    "author" : { "@type" : "Person", "name" : "SAAM" },
    "image" : "https://saam-digital.miurabox.net/assets/benefit/img-conputadoras-beneficios.png",
    "articleBody" : 
    [ 
      "Saam es intuitivo, lógico, portatil y ágil. Lo que necesites siempre estará a dos clics de distancia",
      "Saam es 40% más rápido que otros sistemas de administración de seguros y fianzas.",
      "Puedes operar tu cartera de seguros desde cualquier dispositivo y consultar tu información en cualquier lugar.",
      "es a prueba de errores: los indicadores de operación en tiempo real te ayudarán a que ningún plazo se venza.",
      "Los servidores donde se resguardará la información cuentan con aplicaciones que permiten crear redes privadas y controlar tanto el cifrado de los datos como el acceso a la información.</SPAN><SPAN _ngcontent-dti-c1=\"\" class=\"text-certificate\">También nuestros servidores cuentan con las siguientes certificaciones:</SPAN><SPAN _ngcontent-dti-c1=\"\" class=\"text-certificate-center\">ISO 27001</SPAN><SPAN _ngcontent-dti-c1=\"\" class=\"text-certificate-center\">ISO 27017</SPAN><SPAN _ngcontent-dti-c1=\"\" class=\"text-certificate-center\">ISO 27018</SPAN><SPAN _ngcontent-dti-c1=\"\" class=\"text-certificate-center\">entre otras.</SPAN><SPAN _ngcontent-dti-c1=\"\" class=\"text-certificate\">Estas regulan las normas de gestión de seguridad, los controles específicos de la nube y la protección de datos personales." 
    ],
    "url" : "https://heysaam.com/beneficios",
    "publisher" : { "@type" : "Organization", "name" : "Saam" }
  } 
  constructor(public router: Router, private titleService: Title, private seoService: SEOService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.titleService.setTitle('Beneficios - Saam Digital');
    this.seoService.updateMetaTagsBenefit();
  }

  goToPage(param){
    this.router.navigate(['/' + param]);
  };

}
