import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { RestApiProvider } from '../provider/rest-api';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  showInformation = false;
  showError = false;
  user = '';
  password = '';
  loading = false;

  showAdd = false;
  showItem = false;
  single_file:any;
  filter = {};
  categories = [
    {value: 0 ,name: 'Selecciona una categoría'},
    {value: 1 ,name: 'Agentes de Seguros'},
    {value: 2 ,name: 'Polizas de Seguros'}
  ];
  actions = [
    {value: 0 ,name: 'Selecciona una acción'},
    {value: 1 ,name: 'Agregar'},
    {value: 2 ,name: 'Editar'},
    {value: 3 ,name: 'Eliminar'}
  ];
  itemsA:any = [];
  itemsP:any = [];
  title = {};
  itemRes:any;
  subtitles:any = [];
  comments:any;
  card = {};
  file:any;
  imagen:any;
  showFile = false;
  public imagePath;
  imgURL: any;
  public message: string;

  imageChangedEvent: any = '';
  croppedImage: any = '';

  constructor(public _api: RestApiProvider) { }

  ngOnInit() {
    this.filter['category'] = this.categories[0].name;
    this.filter['action'] = this.actions[0].value;
    window.scrollTo(0, 0);
  }

  goToAdmin(){
    this.loading = true;
    this._api.login(this.user, this.password).then(response=>{
      this.loading = false;
      if(response['token_jwt']){
        this.showInformation = true;
      }
      if(response['error']){
      }
    }).catch(error=>{
      console.log(error);
      Swal.fire('Error', error['error']['error'], 'error');
    })
  };

  logout(){

    this.showInformation = false;
    console.log(this.showInformation);
    
  };

  getActions(){
    this.loading = true;
    this.filter['action'] = this.actions[0].value;
    this.showItem = false;
    this.loading = false;
  };

  getItems(){
    this.loading = true;
    if(this.filter['action'] == 1){
      this.title['title'] = '';
      this.title['text'] = '';
      this.title['priority'] = 0;
      this.showAdd = true;
      this.showItem = false;
      this.itemsA = [];
      this.itemsP = [];
      this.itemsA.unshift({title: 'Selecciona un artículo', id: 0});
      this.filter['item'] = this.itemsA[0].id;
      this.itemsP.unshift({title: 'Selecciona un artículo', id: 0});
      this.filter['item'] = this.itemsP[0].id;
      this.loading = false;
      this.subtitles = [];
      this.imgURL = undefined;
      this.filter['item'] = 0;
      


    }else{
      this.showAdd = false;
      this.showItem = true;

      let param;
      if(this.filter['category'] == 'Agentes de Seguros'){
        param = 'Agentes%20de%20Seguros';
      }else{
        param = 'Polizas%20de%20Seguros';
      }

      this._api.get_cards(param).then(response=>{
        this.loading = false;
        if(response){
          this.itemsA = [];
          this.itemsP = [];
          let dataA = [];
          let dataP = [];
          for(let i = 0; i < response['length']; i++){
            if(response[i]['category'] == 'Agentes de Seguros'){
              dataA.push(response[i]);
            }
            if(response[i]['category'] == 'Polizas de Seguros'){
              dataP.push(response[i]);
            }
          }
          this.itemsA = dataA;
          this.itemsA.unshift({title: 'Selecciona un artículo', id: 0});
          this.filter['item'] = this.itemsA[0].id;
          this.itemsP = dataP;
          this.itemsP.unshift({title: 'Selecciona un artículo', id: 0});
          this.filter['item'] = this.itemsP[0].id;
        }
      },error => {
        this.loading = false;
      }).catch(error=>{
        this.loading = false;
        console.log(error);
      })
    }
  };

  addSubtitle(){
    let sub = {
      title:'',
      text: '',
      priority: 1
    }

    this.subtitles.push(sub);
  }

  deleteSubtitle(item, index){
    console.log(index)
    console.log(item)
    if(item['id']){
      Swal.fire({
        title: 'Eliminar Sutítulo',
        text: "¿Estas seguro?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          this._api.delete_subcards(item['id']).then(response=>{
            Swal.fire('¡Listo!', 'Subtítulo eliminado.', 'success');
            this.subtitles.splice(index, 1);
          }).catch(error=>{
            console.log(error);
          })
        } else {
          Swal.fire('Error', 'Ocurrió un error', 'error')
        }
      })
    }else{
      this.subtitles.splice(index, 1);
    }
  }

  save(){
    this.loading = true;
    this.title['priority'] = 0;
    this.title['category'] = this.filter['category'];

    if (this.filter && this.filter['item']){
      for(let item of this.subtitles){
        this.loading = true;
        this._api.save_subcards(this.filter['item'], item).then(responses=>{
          this.loading = false;
          Swal.fire('¡Listo!', 'Subtitulo guardado.', 'success');
        }).catch(error=>{
          console.log(error);
        })
      }
    } else {
      this._api.save_cards(this.title).then(response=>{
        this.filter['item'] = response['id'];
        Swal.fire('¡Listo!', 'Artículo guardado.', 'success');
        this.loading = false;
        
        for(let item of this.subtitles){
          this.loading = true;
          this._api.save_subcards(response['id'], item).then(responses=>{
            this.loading = false;
            Swal.fire('¡Listo!', 'Subtitulo guardado.', 'success');
          }).catch(error=>{
            console.log(error);
          })
        }
        this.loading = false;
      }, error => {
        this.loading = false;
      }).catch(error=>{
        console.log(error);
        this.loading = false;
      });
    }
    this.filter['action'] = 2;
  }

  imageLoaded(){}

  cropperReady(){}

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.showFile = true;
  }

  b64toBlob(b64Data, contentType, sliceSize = 512) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
    
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  onFileChanged(event) {
    this.single_file = event.target.files[0]
  }

  onUpload() {
    this.loading = true;
    const uploadData = new FormData();
    uploadData.append('file', this.single_file, this.single_file.name);
    this._api.save_image(this.filter['item'],uploadData).then(responses=>{
      this.loading= false;
      this.showFile = false;
      Swal.fire('¡Listo!', 'Imágen guardada.', 'success');
    }, error => {
      this.loading= false;
      Swal.fire('Error!', 'Ocurrió un error al cargar la imágen. inténtelo nuevamente.', 'error');
    }).catch(error=>{
      this.loading= false;
      console.log(error);
      Swal.fire('Error!', 'Ocurrió un error al cargar la imágen. inténtelo nuevamente.', 'error');
    })
  }

  change_imagen(event){
    var ImageURL = this.croppedImage;
    // Split the base64 string in data and contentType
    var block = ImageURL.split(";");
    // Get the content type of the image
    var contentType = block[0].split(":")[1];
    // get the real base64 content of the file
    var realData = block[1].split(",")[1];
    console.log("145",this.imageChangedEvent)
    // Convert it to a blob to upload
    console.log(this.imageChangedEvent.target.name)
    var blob = this.b64toBlob(realData, contentType);
    var file = new File([blob],this.imageChangedEvent.target.name, {
      type: contentType
    });

    // Create a FormData and append the file with "image" as parameter name
    var formDataToUpload = new FormData();
    formDataToUpload.append("avatar", file);
    let payload = {  
      "avatar": this.croppedImage
    }
    
    // this._api.save_image( this.user.userinfo.url.replace('user-info','user-avatar-info') , payload).then(response=>{
    //   this.user.userinfo['avatar'] = response['avatar'];
    //   this.showImageProfile = true;
    //   this._toaster.success('Imagen guardada correctamente');
    // });

    console.log("166",file)

    this._api.save_image(3, file).then(responses=>{
      this.showFile = false;
    }).catch(error=>{
      console.log(error);
    })
  }

  preview(files) {
    if (files.length === 0)
      return;
 
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
 
    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      this.imgURL = reader.result; 
    }
  }

  getInformation(param){
    if(this.itemsA){
      for(let item of this.itemsA){
        if(item.id == param){
          this.title = item;
        }
      }
    }
    if(this.itemsP){
      for(let item of this.itemsP){
        if(item.id == param){
          this.title = item;
        }
      }
    }
  }
  
  getAction(){
    if(this.filter['action'] == 2){
      this.showAdd = true;
      this._api.get_subcards(this.title['id']).then(response=>{
        this.itemRes = response
        if(response){
          for(let item of this.itemRes){
            this.subtitles.push(item);
          }
        }
      }).catch(error=>{
        console.log(error);
      })
    }else if(this.filter['action'] == 3){
      this._api.get_comments(this.title['id']).then(response=>{
        if(response){
          this.comments = response;
        }
      }).catch(error=>{
        console.log(error);
      })
      this._api.get_subcards(this.title['id']).then(response=>{
        if(response){
          this.subtitles = [];
          this.subtitles = response;
        }
      }).catch(error=>{
        console.log(error);
      })
      Swal.fire({
        title: 'Eliminar Artículo',
        text: "¿Estas seguro?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          if(this.comments['length']){
            for (let i = 0; i < this.comments['length']; i++) {
              this._api.delete_comments(this.comments[i]['id']).then(responses=>{
                
              }).catch(error=>{
                console.log(error);
              })
              if((i + 1) == this.comments['length']){
                if(this.subtitles['length']){
                  for (let j = 0; j < this.subtitles['length']; j++) {
                    this._api.delete_subcards(this.subtitles[j]['id']).then(responses=>{
                      
                    }).catch(error=>{
                      console.log(error);
                    })
                    if((j + 1) == this.subtitles['length']){
                      this._api.delete_cards(this.title['id']).then(responses=>{
                        let param;
                        if(this.filter['category'] == 'Agentes de Seguros'){
                          param = 'Agentes%20de%20Seguros';
                        }else{
                          param = 'Polizas%20de%20Seguros';
                        }
                        this._api.get_cards(param).then(response=>{
                          if(response){
                            this.itemsA = [];
                            this.itemsP = [];
                            let dataA = [];
                            let dataP = [];
                            for(let i = 0; i < response['length']; i++){
                              if(response[i]['category'] == 'Agentes de Seguros'){
                                dataA.push(response[i]);
                              }
                              if(response[i]['category'] == 'Polizas de Seguros'){
                                dataP.push(response[i]);
                              }
                            }
                            this.itemsA = dataA;
                            this.itemsA.unshift({title: 'Selecciona un artículo', id: 0});
                            this.filter['item'] = this.itemsA[0].id;
                            this.itemsP = dataP;
                            this.itemsP.unshift({title: 'Selecciona un artículo', id: 0});
                            this.filter['item'] = this.itemsP[0].id;
                          }
                          this.loading = false;
                          Swal.fire('¡Listo!', 'El artículo ha sido eliminado.', 'success');
                        }).catch(error=>{
                          console.log(error);
                        })
                      }).catch(error=>{
                        console.log(error);
                      })
                    }
                  }
                }else{
                  this._api.delete_cards(this.title['id']).then(responses=>{
                    let param;
                    if(this.filter['category'] == 'Agentes de Seguros'){
                      param = 'Agentes%20de%20Seguros';
                    }else{
                      param = 'Polizas%20de%20Seguros';
                    }
                    this._api.get_cards(param).then(response=>{
                      if(response){
                        this.itemsA = [];
                        this.itemsP = [];
                        let dataA = [];
                        let dataP = [];
                        for(let i = 0; i < response['length']; i++){
                          if(response[i]['category'] == 'Agentes de Seguros'){
                            dataA.push(response[i]);
                          }
                          if(response[i]['category'] == 'Polizas de Seguros'){
                            dataP.push(response[i]);
                          }
                        }
                        this.itemsA = dataA;
                        this.itemsA.unshift({title: 'Selecciona un artículo', id: 0});
                        this.filter['item'] = this.itemsA[0].id;
                        this.itemsP = dataP;
                        this.itemsP.unshift({title: 'Selecciona un artículo', id: 0});
                        this.filter['item'] = this.itemsP[0].id;
                      }
                      Swal.fire('¡Listo!', 'El artículo ha sido eliminado.', 'success');
                    }).catch(error=>{
                      console.log(error);
                    })
                  }).catch(error=>{
                    console.log(error);
                  })
                }
              }
            }
          }else{
            if(this.subtitles['length']){
              for (let j = 0; j < this.subtitles['length']; j++) {
                this._api.delete_subcards(this.subtitles[j]['id']).then(responses=>{
                  
                }).catch(error=>{
                  console.log(error);
                })
                if((j + 1) == this.subtitles['length']){
                  this._api.delete_cards(this.title['id']).then(responses=>{
                    let param;
                    if(this.filter['category'] == 'Agentes de Seguros'){
                      param = 'Agentes%20de%20Seguros';
                    }else{
                      param = 'Polizas%20de%20Seguros';
                    }
                    this._api.get_cards(param).then(response=>{
                      if(response){
                        this.itemsA = [];
                        this.itemsP = [];
                        let dataA = [];
                        let dataP = [];
                        for(let i = 0; i < response['length']; i++){
                          if(response[i]['category'] == 'Agentes de Seguros'){
                            dataA.push(response[i]);
                          }
                          if(response[i]['category'] == 'Polizas de Seguros'){
                            dataP.push(response[i]);
                          }
                        }
                        this.itemsA = dataA;
                        this.itemsA.unshift({title: 'Selecciona un artículo', id: 0});
                        this.filter['item'] = this.itemsA[0].id;
                        this.itemsP = dataP;
                        this.itemsP.unshift({title: 'Selecciona un artículo', id: 0});
                        this.filter['item'] = this.itemsP[0].id;
                      }
                      Swal.fire('¡Listo!', 'El artículo ha sido eliminado.', 'success');
                    }).catch(error=>{
                      console.log(error);
                    })
                  }).catch(error=>{
                    console.log(error);
                  })
                }
              }
            }else{
              this._api.delete_cards(this.title['id']).then(responses=>{
                let param;
                if(this.filter['category'] == 'Agentes de Seguros'){
                  param = 'Agentes%20de%20Seguros';
                }else{
                  param = 'Polizas%20de%20Seguros';
                }
                this._api.get_cards(param).then(response=>{
                  if(response){
                    this.itemsA = [];
                    this.itemsP = [];
                    let dataA = [];
                    let dataP = [];
                    for(let i = 0; i < response['length']; i++){
                      if(response[i]['category'] == 'Agentes de Seguros'){
                        dataA.push(response[i]);
                      }
                      if(response[i]['category'] == 'Polizas de Seguros'){
                        dataP.push(response[i]);
                      }
                    }
                    this.itemsA = dataA;
                    this.itemsA.unshift({title: 'Selecciona un artículo', id: 0});
                    this.filter['item'] = this.itemsA[0].id;
                    this.itemsP = dataP;
                    this.itemsP.unshift({title: 'Selecciona un artículo', id: 0});
                    this.filter['item'] = this.itemsP[0].id;
                  }
                  Swal.fire('¡Listo!', 'El artículo ha sido eliminado.', 'success');
                }).catch(error=>{
                  console.log(error);
                })
              }).catch(error=>{
                console.log(error);
              })
            }
          }
        } else {
          Swal.fire('Cancelado', 'No se eliminó el artículo.', 'error')
        }
      })
    }
  }

  update(){
    Swal.fire('¡Listo!', 'El artículo ha sido actualizado.', 'success');
    this._api.edit_cards(this.title['id'], this.title).then(responses=>{
      for(let i = 0; i < this.subtitles.length; i++){
        console.log(this.subtitles[i])
        if(this.subtitles[i]['id']){
          this._api.edit_subcards(this.subtitles[i]['id'], this.subtitles[i]).then(responses=>{
    
          }).catch(error=>{
            console.log(error);
          })
        }else {
          this._api.save_subcards(this.title['id'], this.subtitles[i]).then(responses=>{

          }).catch(error=>{
            console.log(error);
          })
        }
      }
    }).catch(error=>{
      console.log(error);
    })
  }

}
