import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AboutComponent } from './about/about.component';
import { ServiceComponent } from './service/service.component';
import { BenefitComponent } from './benefit/benefit.component';
import { AgentComponent } from './agent/agent.component';
import { SureComponent } from './sure/sure.component';
import { GlossaryComponent } from './glossary/glossary.component';
import { QuestionComponent } from './question/question.component';
import { BlogComponent } from './blog/blog.component';
import { ContactComponent } from './contact/contact.component';
import { LoginComponent } from './login/login.component';
import { TestComponent } from './test/test.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http';
import { RestApiProvider } from './provider/rest-api';
import { SettingsService } from './provider/settings';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { AdminComponent } from './admin/admin.component';

import { ImageCropperModule } from 'ngx-image-cropper';
import { RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AboutComponent,
    ServiceComponent,
    BenefitComponent,
    AgentComponent,
    SureComponent,
    GlossaryComponent,
    QuestionComponent,
    BlogComponent,
    ContactComponent,
    LoginComponent,
    TestComponent,
    AdminComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgxJsonLdModule,
    ImageCropperModule,
    ReactiveFormsModule,
    RecaptchaModule
  ],
  providers: [
    RestApiProvider,
    SettingsService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: '6LcXmrcqAAAAAByLU556PCUOgTy1Hp4ZDV6Yk2Ps' } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
