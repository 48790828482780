import { Component, OnInit } from '@angular/core';
import { RestApiProvider } from '../provider/rest-api';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  user = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirm: ''
  }
  showResponse = false;

  constructor(public _api: RestApiProvider) { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  register(){
    if(this.user.first_name && this.user.last_name && this.user.email && this.user.password){
      this._api.signup(this.user).then(response=>{
        console.log(response);
        if(response['status'] == 201){
          this.showResponse = true;
        }
        else if(response['status'] == 500){
          Swal.fire('Error', response['error'], 'error');
        }
      }).catch(error=>{
        console.log(error);
      })
    }
    else{
      Swal.fire('Error', 'Toda la información es requerida.', 'error');
    }
  };
}
