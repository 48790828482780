import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { SEOService } from '../seo.service';
import { Title, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  schema = {
    "@context" : "http://schema.org",
    "@type" : "LocalBusiness",
    "name" : "Saam",
    "image" : "https://saam-digital.miurabox.net/assets/about/imagen-computadora.png",
    "telephone" : "4426740373",
    "email" : "contacto@miurabox.com",
    "url" : "https://heysaam.com/"
  };
  
  constructor(private router: Router, private titleService: Title, private seoService: SEOService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.titleService.setTitle('Sistema para la Administración de Seguros y Fianzas - Saam Digital');
    this.seoService.updateMetaTagsAbout();
  }

  goToPage(param){
    this.router.navigate(['/' + param]);
  };

}
