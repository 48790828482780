import { Component, OnInit, Injectable } from '@angular/core';
import { RestApiProvider } from '../provider/rest-api';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../provider/settings';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {
  form:FormGroup
  // user = {
  //   email: '',
  //   password: ''
  // }
  user = {
    name: '',
    phone: '',
    email: '',
    message: 'Solicito prueba demo.'
  }
  showForgot = false;
  disabled = true;

  apiUrl:string = '';
  casUrl:string = '';
  mcUrl:string = '';
  urlname:string;
  org:string;
  credencial:any = {
    usuario: 'superuser_cas',
    contrasena: 'gGxgoAHW0TcgRhjfFpbgNGmeeGwAzf8wE2IpHUeDGic0oPcYZf',
    email: ''
  }
  planes: any;

  constructor(
    public _api: RestApiProvider,
    public _ajustes: SettingsService,
    private _validators: ValidadoresService,
    private http: HttpClient
    ) {
    this.form = new FormGroup({
      name : new FormControl(null,[Validators.required]),
      telefono : new FormControl(null,[Validators.required, Validators.minLength(10)]),
      email : new FormControl(null,[Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$"), Validators.required], this._validators.existeEmail.bind(this))
    });

    this.apiUrl = 'https://api.miurabox.com/';
    this.casUrl = 'https://users-api.miurabox.com/';
    this.mcUrl = 'https://gpimc.multicotizador.com/';
    this.urlname = 'gpimc';
    this.org = 'gpi';
    // this.apiUrl = 'http://127.0.0.1:8000/';
    // this.casUrl = 'http://127.0.0.1:9000/';
    // this.mcUrl = 'http://127.0.0.1:8003/';
    // this.urlname = 'local';
    // this.org = 'local';
   }

   resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    this._api.check_captcha(captchaResponse).then((response) =>{
      if (response){
        this.disabled = false;
      }
    }, ()=>{
      
    })
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.login();
  }

  login() {
    let data = {
      username: this.credencial.usuario,
      password: this.credencial.contrasena
    }
    return new Promise((resolve,reject) => {
      this.http.post(this.casUrl + 'api-token-auth-cas/', data, {headers: { 'Content-Type': 'application/json' }})
      .subscribe(data => {
        this._ajustes['token'] = data['token'];
        this._ajustes.saveSettings();
        this.plans();
      }, err => {
        reject(err);
      });
    });
  }

  plans(){
    this._api.plans().then(response => {
      this.planes = response;
      console.log(this.planes)
    }).catch(error => {
      console.log(error);
    });
  };

  forgot(value){
    this.showForgot = value;
  };

  forgot_password(){
    if(!this.user.email){
      Swal.fire('Error', 'Para reenviar el correo de activación ingrese el correo con el que se registró, en el campo de correo', 'error')
    }
    this._api.forgot_password(this.user.email).then(response=>{
      console.log(response);
      if(response['status'] == 'sent'){
        Swal.fire('¡Listo!', 'Correo de restauración de contraseña enviado, verifique que el correo que ingresó sea el mismo con el que creo su usuario.', 'success')
      }else if(response['error']){
        Swal.fire('Error', response['error'], 'error');
        return;
      }
    }).catch(err=>{
      console.log(err)
    })
  };

  sendContact(){
    if(this.form.valid){
      this._api.solicitud_de_prueba(this.form.value).then(response=>{
          Swal.fire('¡Listo!', 'Ya enviamos a tu correo las instrucciones para que comiences a utilizar tu prueba gratuita..', 'success');
          this.form.reset();
      }).catch(error=>{
        console.log(error);
        Swal.fire('Error', 'Ha ocurrido un error, favor de volver a intentarlo', 'error');
      })
    }
    else{
      Object.values(this.form.controls).forEach(control=>{
        control.markAllAsTouched();
      })
      return;
    }
  };

  get emailNoValido(){
    return this.form.get('email').invalid && this.form.get('email').touched;
  }

  get nameNoValido(){
    return this.form.get('name').invalid && this.form.get('name').touched;
  }

  get telefonoNoValido(){
    return this.form.get('telefono').invalid && this.form.get('telefono').touched;
  }
}





@Injectable({
  providedIn: 'root'
})
export class ValidadoresService{
  constructor(private _api: RestApiProvider) {}

  existeEmail(control: FormControl):Promise<ErrorValidate> | null{
    if (!control.value){return Promise.resolve(null)};

    return new Promise((resolve, reject)=>{
    
      this._api.existe_email(control.value).then(response=>{
        if(response){
          resolve({existe:true})
        }
        else{
          resolve(null)
        }
      })
      .catch(err=>{
        console.log(err);
        resolve({existe:true})
      })


    })
  }


}

interface ErrorValidate{
  [s:string]: boolean
}

