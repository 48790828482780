import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../provider/settings';
import { Router } from "@angular/router";
import { SEOService } from '../seo.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit {

  // url = 'http://localhost:4200/preguntas';
  url = 'https://heysaam.com/preguntas';

  schema = {
    "@context":"https://schema.org",
    "@type":"FAQPage","mainEntity":
    [
      {
        "@type":"Question",
        "name":"¿Cómo puede ayudarme SAAM en mi negocio?",
        "acceptedAnswer":
        {
          "@type":"Answer",
          "text":"Auxiliándote a manejar tus pólizas, SAAM ayudará a tener tu información en un solo lugar."
        }
      }
    ]
  };
  
  color = '#bbdefb';
  color_white = '#ffffff';

  showQuestion = true;

  question1 = this.color_white;
  question2 = this.color_white;
  question3 = this.color_white;
  question4 = this.color_white;
  question5 = this.color_white;
  question6 = this.color_white;

  params;

  questions = {};

  constructor(private _setting: SettingsService, private router: Router, private titleService: Title, private seoService: SEOService) { }

  ngOnInit() {
    window.scrollTo(0, 0);

    var URLactual = window.location;
    if(String(URLactual) != this.url){
      this.question1 = this.color_white;
      this.question2 = this.color_white;
      this.question3 = this.color_white;
      this.question4 = this.color_white;
      this.question5 = this.color_white;
      this.question6 = this.color_white;
      switch(this._setting['settings']['questions']['question']){
        case 1:
          this.question1 = this.color;
          break;
        case 2:
          this.question2 = this.color;
          break;
        case 3:
          this.question3 = this.color;
          break;
        case 4:
          this.question4 = this.color;
          break;
        case 5:
          this.question5 = this.color;
          break;
        case 6:
          this.question6 = this.color;
          break;
        default:
          break;
      }
      this.showQuestion = false;
      // this.titleService.setTitle(this._setting['settings']['questions']['title']);
      // this.schema = this._setting['settings']['questions']['schema'];
    }else{
      this.titleService.setTitle('Preguntas - Saam Digital');
    }
    this.seoService.updateMetaTagsQuestion();
  }

  goToFaq(value){
    this.goToQuestion(value);
  };

  goToQuestion(value){
    this.question1 = this.color_white;
    this.question2 = this.color_white;
    this.question3 = this.color_white;
    this.question4 = this.color_white;
    this.question5 = this.color_white;
    this.question6 = this.color_white;
    switch (value){
      case 1:
        this.questions['question'] = 1;
        this.question1 = this.color;
        this.params = 'como-puede-ayudarme-saam-en-mi-negocio';
        this.titleService.setTitle('¿Cómo puede ayudarme SAAM en mi negocio? - Saam Digital');
        this.schema = {
          "@context":"https://schema.org",
          "@type":"FAQPage","mainEntity":
          [
            {
              "@type":"Question",
              "name":"¿Cómo puede ayudarme SAAM en mi negocio?",
              "acceptedAnswer":
              {
                "@type":"Answer",
                "text":"Auxiliándote a manejar tus pólizas, SAAM ayudará a tener tu información en un solo lugar."
              }
            }
          ]
        };
        break;
      case 2:
        this.questions['question'] = 2;
        this.question2 = this.color;
        this.params = 'es-seguro-saam';
        this.titleService.setTitle('¿Es seguro SAAM? - Saam Digital');
        this.schema = {
          "@context":"https://schema.org",
          "@type":"FAQPage","mainEntity":
          [
            {
              "@type":"Question",
              "name":"¿Es seguro SAAM?","acceptedAnswer":
              {
                "@type":"Answer",
                "text":"En SAAM sólo necesitas tu cuenta y tu contraseña para poder acceder a tus pólizas en cualquier lugar, por lo que además de seguro es portátil. Por lo que la información de nuestros clientes siempre estará protegida, a la mano, cifrada, y en la nube."
              }
            }
          ]
        };
        break;
      case 3:
        this.questions['question'] = 3;
        this.question3 = this.color;
        this.params = 'como-puedo-manejar-mi-cartera-desde-la-nube';
        this.titleService.setTitle('¿Cómo puedo manejar mi cartera desde la nube? - Saam Digital');
        this.schema = {
          "@context":"https://schema.org",
          "@type":"FAQPage",
          "mainEntity":
          [
            {
              "@type":"Question",
              "name":"¿Cómo puedo manejar mi cartera desde la nube?",
              "acceptedAnswer":
              {
                "@type":"Answer",
                "text":"Al tener tu información en SAAM, puedes acceder a ella desde cualquier dispositivo."
              }
            }
          ]
        };
        break;
      case 4:
        this.questions['question'] = 4;
        this.question4 = this.color;
        this.params = 'si-ya-cuento-con-un-sistema-de-manejo-de-polizas-y-quiero-cambiarme-a-saam-puedo-tener-todas-las-polizas-conmigo';
        this.titleService.setTitle('¿Puedo tener todas las pólizas conmigo? - Saam Digital');
        this.schema = {
          "@context":"https://schema.org",
          "@type":"FAQPage",
          "mainEntity":
          [
            {
              "@type":"Question",
              "name":"Si ya cuento con un sistema de manejo de pólizas y quiero cambiarme a SAAM, ¿puedo tener todas las pólizas conmigo?",
              "acceptedAnswer":
              {
                "@type":"Answer",
                "text":"Sí, es posible que tener todas tus antiguas pólizas antiguas en SAAM, y comenzar a expandir tu negocio."
              }
            }
          ]
        };
        break;
      case 5:
        this.questions['question'] = 5;
        this.question5 = this.color;
        this.params = 'necesito-instalar-algún-software-en-especial-o-necesito-entrar-desde-un-navegador-en-especial';
        this.titleService.setTitle('¿Necesito instalar algún software en especial? - Saam Digital');
        this.schema = {
          "@context":"https://schema.org",
          "@type":"FAQPage",
          "mainEntity":
          [
            {
              "@type":"Question",
              "name":"¿Necesito instalar algún software en especial o necesito entrar desde un navegador en especial?",
              "acceptedAnswer":
              {
                "@type":"Answer",
                "text":"No, al estar la información desde la nube, puedes entrar desde cualquier dispositivo con Internet, sin necesidad de instalar algo."
              }
            },{
              "@type":"Question",
              "name":"",
              "acceptedAnswer":{"@type":"Answer","text":""}
            }
          ]
        };
        break;
      case 6:
        this.questions['question'] = 6;
        this.question6 = this.color;
        this.params = 'aun-no-me-convence-del-todo-es-posible-tener-una-prueba-gratuita';
        this.titleService.setTitle('¿Es posible tener una prueba gratuita? - Saam Digital');
        this.schema = {
          "@context":"https://schema.org",
          "@type":"FAQPage",
          "mainEntity":
          [
            {
              "@type":"Question",
              "name":"¿Aún no me convence del todo ¿es posible tener una prueba gratuita?",
              "acceptedAnswer":
              {
                "@type":"Answer",
                "text":"Claro que sí, sólo entra a https://saam.digital/ para activar tu prueba gratis."
              }
            }
          ]
        };
        break;
      default:
        break;
    }
    
    this._setting['settings']['questions'] = this.questions;
    this._setting.saveSettings();
    this.router.navigate(['/preguntas', this.params]);
  };
}
