import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from "@angular/router";
import Swal from 'sweetalert2';
import { RestApiProvider } from '../provider/rest-api';
import { SEOService } from '../seo.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  disabled = true;
  schema = {
    "@context" : "http://schema.org",
    "@type" : "LocalBusiness",
    "name" : "Contacto",
    "image" : "https://saam-digital.miurabox.net/assets/about/imagen-computadora.png",
    "telephone" : "4426740373",
    "email" : "contacto@miurabox.com",
    "url" : "https://heysaam.com/"
  }
  contact = {
    name: '',
    email: '',
    phone: '',
    message: ''
  }

  // Usa esta clave de sitio web en el código HTML que tu sitio web sirve a los usuarios.
  // 6Lf2caMZAAAAACW3Av_oZFL0VUEgmSiAWBQ7xqBG

  // Usa esta clave secreta para la comunicación entre tu sitio web y el servicio reCAPTCHA.
  // 6Lf2caMZAAAAAMheP14vBzHL4bMtzZL0pF_GY6Cg

  @ViewChild('recaptcha', {static: true }) recaptchaElement: ElementRef;

  constructor(private router: Router, public _api: RestApiProvider, private titleService: Title) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.titleService.setTitle('Contáctanos - Saam Digital');
    // this.seoService.updateMetaTagsContact();
    // this.addRecaptchaScript();
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    this._api.check_captcha(captchaResponse).then((response) =>{
      if (response){
        this.disabled = false;
      }
    }, ()=>{
      
    })
  }

  sendContact(){
    if(this.contact.name && this.contact.email && this.contact.phone && this.contact.message){
      this._api.send_contact(this.contact).then(response=>{
        if(response['status'] == 'sent'){
          Swal.fire('¡Listo!', 'El correo fue enviado correctamente.', 'success');
          this.disabled = true;
        }
        else if(response['status'] == 500){
          Swal.fire('Error', response['error'], 'error');
          this.disabled = true;
        }
      }).catch(error=>{
        console.log(error);
        this.disabled = true;
      })
    }
    else{
      Swal.fire('Error', 'Toda la información es requerida.', 'error');
    }
  };

  goToPage(param){
    this.router.navigate(['/' + param]);
  };

  addRecaptchaScript() {
    window['grecaptchaCallback'] = () => {
      this.renderReCaptcha();
    }

    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));
  }

  renderReCaptcha() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey' : '6Lf2caMZAAAAACW3Av_oZFL0VUEgmSiAWBQ7xqBG',
      'callback': (response) => {
          console.log(response);
      }
    });
  }

}
